import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Home from "./pages/Home/HomeV2";
import Mobile from "./pages/Mobile/MobileV2";
import isMobile from "is-mobile";

function RouterPages() {
  const history = useHistory();
  useEffect(() => {
    if (isMobile()) {
      history.push("/mobile");
    } else {
      history.push("/");
    }
  }, [history]);
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/mobile">
        <Mobile />
      </Route>
      <Route path="*">
        <Home />
      </Route>
    </Switch>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <RouterPages />
      </Router>
    </div>
  );
}

export default App;
