import React from "react";
import styles from "./MobileV2.module.scss";

function MobileCopy() {
  return (
    <div className={styles.mobile}>
      <div className="header">
        <span className="logo" />
        <div className="share-wrap">
          <a
            className="item share1"
            href="https://twitter.com/litra_finance"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            className="item share2"
            href="https://t.me/litra_finance"
            target="_blank" rel="noreferrer"
          ></a>
          <a
            className="item share3"
            href="https://medium.com/@litra_finance"
            target="_blank" rel="noreferrer"
          ></a>
          <a
            className="item share4"
            href="https://litra.gitbook.io/docs/"
            target="_blank" rel="noreferrer"
          ></a>
        </div>
      </div>
      <div className="content">
        <div className="text1"></div>
        <div className="text2">
        Unleash the superliquidity of NFTs
        </div>
        <a
          className="launch-app"
          href="https://litra.finance/app/"
          target="_blank" rel="noreferrer"
        >
          Launch APP
        </a>
      </div>
    </div>
  );
}

export default MobileCopy;
