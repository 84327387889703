import React from "react";
import styles from "./HomeV2.module.scss";

function HomeCopy() {
  return (
    <div className={styles.HomeV2}>
      <span className="logo" />
      <div className="share-wrap">
        <a
          className="item share1"
          href="https://twitter.com/litra_finance"
          target="_blank"
          rel="noreferrer"
        ></a>
        <a
          className="item share2"
          href="https://t.me/litra_finance"
          target="_blank" rel="noreferrer"
        ></a>
        <a
          className="item share3"
          href="https://medium.com/@litra_finance"
          target="_blank" rel="noreferrer"
        ></a>
        <a
          className="item share4"
          href="https://litra.gitbook.io/docs/"
          target="_blank" rel="noreferrer"
        ></a>
      </div>
      {/* <div className="connect-wallet">
        <span>Connect wallet</span>
      </div> */}
      <div className="text1"></div>
      <div className="text2">
      Unleash the superliquidity of NFTs
      </div>
      <a
        className="launch-app"
        href="https://litra.finance/app/"
        target="_blank" rel="noreferrer"
      >
        Launch APP
      </a>
      <div className="tvl-num">
        {/* <div className="item">
          <div className="value">$3,259.1m</div>
          <div className="label">TVL</div>
        </div>
        <div className="item">
          <div className="value">$370.9m</div>
          <div className="label">NUM</div>
        </div> */}
      </div>
    </div>
  );
}

export default HomeCopy;
